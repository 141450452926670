import React from "react";

export default class InputNome extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            nome: 'Fleek Cursos'
        }

        this.changeNome = this.changeNome.bind(this)

    }

    changeNome(event) {
        this.setState({ nome: event.target.value })
    }

    render() {
        return (
            <div>
                <input type="text" onChange={this.changeNome} />
                <p>
                    O nome é: {this.state.nome}
                </p>
            </div>
        )
    }

}